export const GRID = {
  HEIGHT: 56,
  WIDTH: 70,
}

export const CELL = {
  HEIGHT: 9,
  WIDTH: 9,
}

export const MACHINE = {
  HEIGHT: 7,
  WIDTH: 7,
}

export const ORFICE = {
  HEIGHT: 5,
  WIDTH: 5,
}

export const PLAYER = {
  HEIGHT: 13,
  WIDTH: 13,
}
